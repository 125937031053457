import React from 'react';
import { Grid, Row, Col } from '@r007/react-styled-flexboxgrid';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Segmented from '../components/Segmented';
import Layout from '../layouts/Layout';
import Heading from '../components/Heading';
import NewsletterForm from '../components/NewsletterForm';
import ContactUs from '../components/ContactUs';
import { Spacing } from '../components/common';
import accountspayable from './files/AccountsPayable.pdf';
import healthsafetyandenvironmentadvisor from './files/HeathSafetyandEnvironmentAdvisor.pdf';
import integratedlogisticssupportengineer from './files/ILSEngineer.pdf';
import qualityassurancemanager from './files/QualityAssuranceManager.pdf';

const Accent = styled.span`
  color: #4ab4e6;
  font-weight: 700;
`;

const CenteredCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function CareersPage() {
  return (
    <Layout>
      <SEO
        title="Careers"
        description="Working with Ocius &#8211; &#8220;Oceans is us&#8221; Ocius is an Australian public unlisted
      Sydney-based SME (small to medium enterprise) operating at the cutting edge of technology to design and
      build autonomous robots."
      />
      <section className="page-content">
        <Grid>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Spacing $value="80px" />
              <Heading level={1} size="huge" weight="thick" underline="left">
                Careers
              </Heading>
            </Col>
          </Row>
          <Spacing $value="20px" $xsValue="0" />

          <Segmented multiplier={2} borderBottom>
            <Row>
              <CenteredCol xs={12} md={8} lg={8}>
                <Heading level={2} size="medium" weight="thick">
                  About Us.
                </Heading>
                <p>
            Ocius is unlike any company you've worked for before. An unlisted Australian-owned company, we design, manufacture, and deploy high-quality, technologically advanced autonomous vessels for customers across various sectors. Our mission is to protect our borders, preserve our maritime environment, and enhance Australia's social impact and capabilities.
                </p>
<Heading level={2} size="medium" weight="thick">
                  Who are we.
                </Heading>
                <p>
                  We are a bunch of innovative thought leaders with diverse capabilities, experiences and ideas. We bring this diversity to the table to design, ideate, test, launch and continuously improve our offering. 
From Engineers, to Software Designers, Data Scientists, our team comprises the best of breed. 
                </p>
<Heading level={2} size="medium" weight="think">
                The Benefits of Working with Us.
                </Heading>
                <ul>
<li>Innovative Research and Development: We thrive on cutting-edge R&D that drives our success!</li>
<li>Collaborative Team Culture: Join a friendly and intelligent team that fosters a supportive environment.</li>
<li>Friday Lunches: Celebrate achievements together with complimentary lunches every Friday.</li>
<li>Health and Wellbeing Programs: Access a variety of health and wellness initiatives to support your well-being.</li>
<li>Family Planning and Parenting Support: Enjoy resources and programs designed to assist with family planning and parenting.</li>
<li>Generous Parental Leave: Benefit from two weeks of fully paid parental leave, in addition to national entitlements.</li>
<li>Exclusive Discounts: Enjoy discounts on retail, health, and gym memberships.</li>
<li>Mental Health Resources: Access free mental health support, including therapy and life coaching.</li>
<li>Work-Life Support Services: Take advantage of complimentary legal and financial services.</li>
<li>Professional Development: We invest in your growth with company-paid training and development opportunities.</li>
<li>Flexible Work Arrangements: Enjoy a work environment that adapts to your needs.</li>
<li>Competitive Remuneration offering with participation in short term incentive programs (cash bonus); and eligibility to participate in employee share ownership schemes.</li>
</ul>                
<p>For more information contact <a href="mailto:hr@ocius.com.au">hr@ocius.com.au</a></p>

                
                
<Heading level={2} size="medium" weight="thick">
                  What it takes to work here.
                </Heading>
                <p>
                  We are a fast paced, high performing team. We are resilient. We are agile. We work together to deliver results. Every time. We are open minded, and learn everyday. We roll up our sleeves to get the job done.
                </p>
<Heading level={2} size="medium" weight="thick">
                  Ready to grow your career?
                </Heading>
                <p>
                 We don't recruit for roles. We recruit for our future organisation. 
Regardless where you are in your career journey from graduate, through to senior executive, we welcome interest from people of all backgrounds and capabilities.
                </p>
                <p> We provide mentorship, on the job training, coaching and hands on experience in a research and development environment unlike any other. Are you ready to be part of something big?
                </p>
                    <p>
Send your resume to Human Resources <a href="mailto:hr@ocius.com.au">hr@ocius.com.au</a>
</p>
<Heading level={1} size="huge" weight="thick" underline="left">
                Vacant Roles
              </Heading>
<Heading level={2} size="medium" weight="thick">
                 Accounts Payable Specialist
                </Heading>
<p>
The Accounts Payable Specialist is responsible for managing the company's accounts payable duties, ensuring that all invoices are processed accurately and timely, and maintaining positive relationships with vendors. This role requires strong attention to detail, organisational skills, and the ability to handle multiple tasks in a fast-paced environment.
                </p>
<p>
		For more information:  <a href={accountspayable} >AccountsPayable.pdf</a>
</p>
<Heading level={2} size="medium" weight="thick">
                 Health, Safety and Environment Advisor
                </Heading>
<p>
The Senior HSE Advisor will be responsible for the Workplace Health Safety and Environment (HSE) program for the Company. 

Summary of Key Responsibilities include: 

- Develop the workplace HSE program for the Company, implement ISO 45001 and gain certification. 
- Develop ISO 14001 Environmental management systems and gain certification. 
- Develop all safety procedures ensuring they comply with current legislation and standards.
                </p>
<p>
		For more information:  <a href={healthsafetyandenvironmentadvisor}>HealthSafetyandEnvironmentAdvisor.pdf</a>
</p>
<Heading level={2} size="medium" weight="thick">
                 Integrated Logistics Support Engineer
                </Heading>
<p>
The Integrated Logistics Support Engineer is responsible for the execution of all integrated logistics engineering activities at Ocius. This is a newly created role, designed to ensure a seamless transfer from project to full system operation.
                </p>
<p>
		For more information:  <a href={integratedlogisticssupportengineer} >ILSEngineer.pdf</a>
</p>
<Heading level={2} size="medium" weight="thick">
                 Quality Assurance Manager
                </Heading>
<p>
The Manager - Quality Assurance will be responsible for owning all aspects of Quality - developing and managing the quality assurance program for the Company. This is an opportunity for someone to make the mark in Quality, with a successful company. Own all aspects of Quality to support production of high-quality USV products. Lead and own the Quality Management System with a sustainable path to maintain AS9100 certification.
                </p>
<p>
		For more information:  <a href={qualityassurancemanager} >QualityAssuranceManager.pdf</a>
</p>

              </CenteredCol>
            </Row>
          </Segmented>
        </Grid>

        <Heading className="centered" level={3} size="small" weight="thick" color="#4ab4e6">
          For further details please contact <a href="mailto:hr@ocius.com.au">hr@ocius.com.au</a>.
        </Heading>

        <Segmented>
          <NewsletterForm />
        </Segmented>
        <Segmented>
          <ContactUs />
        </Segmented>
      </section>
    </Layout>
  );
}

export default CareersPage;
